import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { FormattedMessage } from 'react-intl';
import styles from '../WifiConnect.module.scss';

export type InstructionsTabsProps = {
  username: string;
  password: string;
  network_name: string;
};

type formattedOptions = {
  color?: string;
  style?: textStyle;
  removeSeparator?: boolean;
};

enum textStyle {
  BOLD = 'bold',
  ITALIC = 'italic',
}

export const InstructionsAccordion: React.FC<InstructionsTabsProps> = ({
  username,
  password,
  network_name,
}) => {
  function formattedMessage(
    txt: string,
    options: formattedOptions = {
      color: '',
      removeSeparator: false,
      style: textStyle.BOLD,
    },
  ) {
    let match = txt.split(' = ');
    let match0 = options.removeSeparator ? match[0] : match[0] + ' = ';

    if (options.color) {
      return (
        <>
          <p>
            {match0} <b style={{ color: options.color }}>{match[1]}</b>
          </p>
        </>
      );
    }

    if (options.style === 'italic') {
      return (
        <>
          <p>
            {match0} <em style={{ color: options.color }}>{match[1]}</em>
          </p>
        </>
      );
    }

    return (
      <>
        <p>
          {match0} {match[1]}
        </p>
      </>
    );
  }

  return (
    <>
      <div className={styles.mediumTitle}>
        <h3>
          <FormattedMessage
            id="tabs.personal.instructions.title"
            defaultMessage="Setup instructions"
          />
        </h3>
      </div>
      <Accordion allowZeroExpanded className={styles.accordion}>
        <AccordionItem className={styles.accordionItem}>
          <AccordionItemHeading className={styles.accordionHeader}>
            <AccordionItemButton className={styles.accordionItemBtn}>
              Windows
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className={styles.accordionItemContent}>
            <h4 className={styles.setupInstructionsSubTitle}>
              <FormattedMessage
                id="tabs.personal.instructions.tabs.windows.title"
                defaultMessage="Here's how to connect to WiFi if you're using Windows:"
              />
            </h4>
            <div>
              <FormattedMessage
                id="tabs.personal.instructions.tabs.windows.subtitle"
                defaultMessage="Select the WiFi network named ''{network_name}'' and enter your username and password listed above."
                values={{ network_name: network_name }}
              />
              <br />
              <br />
              <FormattedMessage
                id="tabs.personal.instructions.tabs.windows.postscripts.0"
                defaultMessage="Then click 'OK'."
              />
              <br />
              <br />
              <FormattedMessage
                id="tabs.personal.instructions.tabs.windows.postscripts.1"
                defaultMessage="After clicking 'OK' to connect your device to ''{network_name}'' for the first time, you will be prompted to trust the server certificate in order to connect. Trusting the certificate is required in order to connect to ''{network_name}''. This is expected behavior when connecting to an 802.1X network."
                values={{ network_name: network_name }}
              />
              <br />
              <br />
              <FormattedMessage
                id="tabs.personal.instructions.tabs.windows.postscripts.2"
                defaultMessage="You should be all set!"
              />
              <br />
              <br />
              <em>
                <FormattedMessage
                  id="tabs.personal.instructions.tabs.windows.postscripts.3"
                  defaultMessage="Please note that if you forget the network on your device, you will be asked to trust the certificate again when you rejoin."
                />
              </em>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className={styles.accordionItem}>
          <AccordionItemHeading className={styles.accordionHeader}>
            <AccordionItemButton className={styles.accordionItemBtn}>
              MacOS
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className={styles.accordionItemContent}>
            <h4 className={styles.setupInstructionsSubTitle}>
              <FormattedMessage
                id="tabs.personal.instructions.tabs.macos.title"
                defaultMessage="Here's how to connect to WiFi if you're using MacOS:"
              />
            </h4>
            <div>
              <FormattedMessage
                id="tabs.personal.instructions.tabs.macos.subtitle"
                defaultMessage="Select the WiFi network named ''{network_name}'' and fill in the form as follows:"
                values={{ network_name: network_name }}
              />
              <br />
              <ul>
                <li>
                  <FormattedMessage
                    id="tabs.personal.instructions.tabs.macos.instructions.0"
                    defaultMessage="Mode: Automatic"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="tabs.personal.instructions.tabs.macos.instructions.1"
                    defaultMessage="Username: ''{username}''"
                    values={{ username: username }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="tabs.personal.instructions.tabs.macos.instructions.2"
                    defaultMessage="Password: ''{password}''"
                    values={{ password: password }}
                  />
                </li>
              </ul>
              <br />
              <FormattedMessage
                id="tabs.personal.instructions.tabs.macos.postscripts.0"
                defaultMessage="Then click 'Join'."
              />
              <br />
              <br />
              <FormattedMessage
                id="tabs.personal.instructions.tabs.macos.postscripts.1"
                defaultMessage="After clicking 'OK' to connect your device to ''{network_name}'' for the first time, you will be prompted to trust the server certificate in order to connect. Trusting the certificate is required in order to connect to ''{network_name}''. This is expected behavior when connecting to an 802.1X network."
                values={{ network_name: network_name }}
              />
              <br />
              <br />
              <FormattedMessage
                id="tabs.personal.instructions.tabs.macos.postscripts.2"
                defaultMessage="You should be all set!"
              />
              <br />
              <br />
              <em>
                <FormattedMessage
                  id="tabs.personal.instructions.tabs.macos.postscripts.3"
                  defaultMessage="Please note that if you forget the network on your device, you will be asked to trust the certificate again when you rejoin."
                />
              </em>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className={styles.accordionItem}>
          <AccordionItemHeading className={styles.accordionHeader}>
            <AccordionItemButton className={styles.accordionItemBtn}>
              iOS
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className={styles.accordionItemContent}>
            <h4 className={styles.setupInstructionsSubTitle}>
              <FormattedMessage
                id="tabs.personal.instructions.tabs.ios.title"
                defaultMessage="Here's how to connect to WiFi if you're using an iOS device:"
                values={{ network_name: network_name }}
              />
            </h4>
            <div>
              <FormattedMessage
                id="tabs.personal.instructions.tabs.ios.subtitle"
                defaultMessage="Select the WiFi network named ''{network_name}'' and enter your username and password listed above."
                values={{ network_name: network_name }}
              />
              <br />
              <br />
              <FormattedMessage
                id="tabs.personal.instructions.tabs.ios.postscripts.0"
                defaultMessage="Then click 'Join' and you should be all set!"
              />
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className={styles.accordionItem}>
          <AccordionItemHeading className={styles.accordionHeader}>
            <AccordionItemButton className={styles.accordionItemBtn}>
              Android / Chromebook / Linux
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className={styles.accordionItemContent}>
            <h4 className={styles.setupInstructionsSubTitle}>
              <FormattedMessage
                id="tabs.personal.instructions.tabs.android.title"
                defaultMessage="Here's how to connect to WiFi if you're using an Android or Chromebook device:"
              />
            </h4>
            <ol>
              <li>
                <FormattedMessage
                  id="tabs.personal.instructions.tabs.android.subTitles.0"
                  defaultMessage="Select the WiFi network named ''{network_name}''"
                  values={{ network_name: network_name }}
                >
                  {(txt: string) =>
                    formattedMessage(txt, { removeSeparator: true })
                  }
                </FormattedMessage>
              </li>
              <li>
                <FormattedMessage
                  id="tabs.personal.instructions.tabs.android.subTitles.1"
                  defaultMessage="Adjust the following settings as needed for your particular Android device."
                />
                <ol type={'a'} style={{ listStyleType: 'lower-alpha' }}>
                  <li>
                    <FormattedMessage
                      id="tabs.personal.instructions.tabs.android.instructions.0"
                      defaultMessage="Security = 'EAP'"
                    >
                      {(txt: string) => formattedMessage(txt)}
                    </FormattedMessage>
                  </li>
                  <li>
                    <FormattedMessage
                      id="tabs.personal.instructions.tabs.android.instructions.1"
                      defaultMessage="EAP Method = 'PEAP'"
                    >
                      {(txt: string) => formattedMessage(txt)}
                    </FormattedMessage>
                  </li>
                  <li>
                    <FormattedMessage
                      id="tabs.personal.instructions.tabs.android.instructions.2"
                      defaultMessage="EAP Phase 2 authentication = 'Automatic or MSCHAPV2'"
                    >
                      {(txt: string) => formattedMessage(txt)}
                    </FormattedMessage>
                  </li>
                  <li>
                    <FormattedMessage
                      id="tabs.personal.instructions.tabs.android.instructions.3"
                      defaultMessage="Server CA certificate = 'Default or Use system certificates**'"
                    >
                      {(txt: string) => formattedMessage(txt)}
                    </FormattedMessage>
                  </li>
                  <li>
                    <FormattedMessage
                      id="tabs.personal.instructions.tabs.android.instructions.4"
                      defaultMessage="MAC Address Type = 'Phone MAC'"
                    >
                      {(txt: string) => formattedMessage(txt)}
                    </FormattedMessage>
                  </li>
                  <li>
                    <FormattedMessage
                      id="tabs.personal.instructions.tabs.android.instructions.5"
                      defaultMessage="Domain = 'wenw.net'"
                    >
                      {(txt: string) => formattedMessage(txt)}
                    </FormattedMessage>
                  </li>
                  <li>
                    <FormattedMessage
                      id="tabs.personal.instructions.tabs.android.instructions.6"
                      defaultMessage="Identity: Enter ''{username}''"
                      values={{ username: username }}
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="tabs.personal.instructions.tabs.android.instructions.7"
                      defaultMessage="Password: Enter ''{password}''"
                      values={{ password: password }}
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="tabs.personal.instructions.tabs.android.instructions.8"
                      defaultMessage="Anonymous identity = leave this field blank"
                    >
                      {(txt: string) =>
                        formattedMessage(txt, { style: textStyle.ITALIC })
                      }
                    </FormattedMessage>
                  </li>
                </ol>
              </li>
              <li>
                <FormattedMessage
                  id="tabs.personal.instructions.tabs.android.postscripts.0"
                  defaultMessage="Select = 'Connect'"
                >
                  {(txt: string) =>
                    formattedMessage(txt, { removeSeparator: true })
                  }
                </FormattedMessage>
              </li>
              <li>
                <FormattedMessage
                  id="tabs.personal.instructions.tabs.android.postscripts.1"
                  defaultMessage="Processed with any certificate authentication prompts"
                />
              </li>
            </ol>
            <br />
            <FormattedMessage
              id="tabs.personal.instructions.tabs.android.postscripts.2"
              defaultMessage="You should be all set!"
            />
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};
