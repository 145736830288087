import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../WifiConnect.module.scss';

export type CorporateTabDescription = {
  network_name: string;
};

export const CorporateTabDescription: React.FC<CorporateTabDescription> = ({
  network_name,
}) => {
  return (
    <div className="ray-grid__cell--span-full" style={{ marginBottom: 15 }}>
      <span>
        <FormattedMessage
          id="tabs.corporate.description.message"
          defaultMessage="Use {network} network to connect your corporate device."
          values={{ network: <b>{network_name}</b> }}
        />
      </span>
    </div>
  );
};
