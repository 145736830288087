import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { AppState } from '@auth0/auth0-react/dist/auth0-provider';

import { Auth0Config } from '../constants/config';
import { AuthenticatedAppWrapper } from '../router/AppRouter';
import { history } from '../router/history';

import { template } from './unsupported-browser';
import { isSupported } from '../utils/utils';

const onRedirectCallback = (appState?: AppState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};

export const App: React.FC = () => (
  <>
    {!isSupported() ? (
      <div dangerouslySetInnerHTML={{ __html: template }} />
    ) : (
      <Auth0Provider
        domain={Auth0Config.domain}
        clientId={Auth0Config.clientID}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        audience="wework"
        cacheLocation="localstorage"
        useRefreshTokens={true}
      >
        <AuthenticatedAppWrapper />
      </Auth0Provider>
    )}
  </>
);
