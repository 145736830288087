import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popover } from 'reakit/Popover';
import styles from '../../WifiConnect.module.scss';

export type ActionsProps = {
  props: any;
  onEdit: Function;
  onDelete: Function;
};

export const Actions: React.FC<ActionsProps> = ({
  props,
  onEdit,
  onDelete,
}) => {
  return (
    <Popover
      {...props}
      tabIndex={0}
      className={styles.actions}
      aria-label="Actions"
    >
      <a
        className={classNames(styles.actionsItem, styles.editAction)}
        onClick={() => onEdit()}
      >
        <span>
          <FormattedMessage
            id="tabs.device.table.actions.edit"
            defaultMessage="Edit"
          />
        </span>
      </a>

      <a
        className={classNames(styles.actionsItem, styles.deleteAction)}
        onClick={() => onDelete()}
      >
        <span>
          <FormattedMessage
            id="tabs.device.table.actions.delete"
            defaultMessage="Delete"
          />
        </span>
      </a>
    </Popover>
  );
};
