import classNames from 'classnames';
import React, { useState } from 'react';
import styles from '../WifiConnect.module.scss';

export type PasswordFieldProps = {
  shared_key: string;
};

export const PasswordField: React.FC<PasswordFieldProps> = ({ shared_key }) => {
  const [isHidden, setIsHidden] = useState<boolean>(true);
  return (
    <>
      <span className={styles.passwordFieldWrapper}>
        <b>{isHidden ? '*'.repeat(shared_key.length) : shared_key}</b>
        <span
          onClick={() => setIsHidden(!isHidden)}
          className={classNames(
            styles.passwordFieldBtn,
            isHidden ? styles.showBtn : styles.hideBtn,
          )}
        ></span>
      </span>
    </>
  );
};
