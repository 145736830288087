import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  editingOptions,
  maxDevices,
  options,
  personalNetworkDevices,
  notifiableDevices,
} from '../../../../constants/app';
import { AppConfig } from '../../../../constants/config';
import { getDeviceClient } from '../../../../api/devices/client';
import { validateMAC } from '../../../../api/devices/helpers';
import { DeviceResponse } from '../../../../api/devices/types';
import styles from '../../WifiConnect.module.scss';
import { Input } from '../Input/Input';
import { Select } from '../Input/Select';
import {
  getLocationsClient,
  MemberLocation,
} from '../../../../api/locations/client';
import useAsyncEffect from 'use-async-effect';

export interface FormInterface {
  submit: () => void;
  cleanup: () => void;
}

type FormProps = {
  showFullForm: boolean;
  locations: MemberLocation[];
  currentMAC?: string;
  className?: any;
  deviceData?: FormState;
  onCreate?: (resp: DeviceResponse) => void;
  onEdit?: (resp: DeviceResponse) => void;
  onSuccess?: () => void;
  onError?: (msg: JSX.Element) => void;
  onFinished?: () => void;
  onSetField?: (name: string, value: any) => void;
  onValidate?: (isValid: boolean) => void;
  onLoad?: () => JSX.Element;
};

export type FormState = {
  mac: string;
  name: string;
  type: string;
  otherType: string;
  location: string;
};

type Errors = {
  [key: string]: boolean;
};

export const Form = forwardRef<FormInterface, FormProps>(
  (
    {
      currentMAC,
      locations,
      className,
      deviceData,
      onCreate,
      onEdit,
      onFinished,
      onSuccess,
      onError,
      onSetField,
      onValidate,
      onLoad,
    },
    ref,
  ) => {
    const [isInAction, setIsInAction] = useState<boolean>(false);
    const [, setIsFinished] = useState<boolean>(false);
    const [isOtherType, setIsOtherType] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [additionalLocations, setAdditionalLocations] = useState<
      MemberLocation[]
    >([]);

    const { getAccessTokenSilently } = useAuth0();
    const intl = useIntl();

    const initialFormState: FormState = {
      name: '',
      mac: '',
      type: '',
      otherType: '',
      location: '',
    };

    const initialErrorsState: Errors = {
      name: false,
      mac: false,
      otherType: false,
      location: false,
    };

    const [mac, setMAC] = useState<string>('');
    const [form, setForm] = useState<FormState>(initialFormState);
    const [errors, setErrors] = useState<Errors>(initialErrorsState);

    const actions = {
      onCreate: async () => {
        setIsInAction(true);

        if (formIsValid()) {
          const token = await getAccessTokenSilently();
          const resp = await getDeviceClient(
            AppConfig.appEnv,
            token,
          ).createDevice({
            mac: form.mac,
            name: form.name,
            type: isOtherType ? form.otherType : form.type,
            location_uuid: form.location,
            wifi_corp_devices: false,
          });

          if ('status' in resp) {
            if (onError) {
              onError(
                <div className={classNames(className, styles.textFieldWrapper)}>
                  <div
                    className={classNames(styles.notification, styles.errorMsg)}
                  >
                    <FormattedMessage
                      id={`tabs.device.messages.errors.${resp.status}`}
                      values={{ maxDevices: maxDevices }}
                      defaultMessage="Internal error. Check your Internet connection and try again"
                    />
                  </div>
                </div>,
              );
            }
          } else {
            if (onCreate) {
              onCreate(resp);
            }

            setIsFinished(true);
            cleanup();

            if (onSuccess) {
              onSuccess();
            }
          }

          if (onFinished) {
            onFinished();
          }
        }

        setIsInAction(false);
      },
      onEdit: async () => {
        setIsInAction(true);

        if (formIsValid()) {
          if (!currentMAC) {
            return;
          }

          const token = await getAccessTokenSilently();
          const resp = await getDeviceClient(
            AppConfig.appEnv,
            token,
          ).updateDevice(mac, {
            mac: form.mac,
            name: form.name,
            type: isOtherType ? form.otherType : form.type,
            location_uuid: form.location,
          });

          if ('status' in resp) {
            if (onError) {
              onError(
                <div className={classNames(className, styles.textFieldWrapper)}>
                  <div
                    className={classNames(styles.notification, styles.errorMsg)}
                  >
                    <FormattedMessage
                      id={`tabs.device.messages.errors.${resp.status}`}
                      values={{ maxDevices: maxDevices }}
                      defaultMessage="Internal error. Check your Internet connection and try again"
                    />
                  </div>
                </div>,
              );
            }
          } else {
            if (onEdit) {
              onEdit(resp);
            }

            setIsFinished(true);
            cleanup();

            if (onSuccess) {
              onSuccess();
            }
          }

          if (onFinished) {
            onFinished();
          }
        }

        setIsInAction(false);
      },
      loadAdditionalLocations: async (locationUUID: string) => {
        setIsInAction(true);

        const token = await getAccessTokenSilently();
        const resp = await getLocationsClient(
          AppConfig.appEnv,
        ).getLocationByUUID(token, locationUUID, navigator.language);

        if ('status' in resp) {
          if (onError) {
            onError(
              <div className={classNames(className, styles.textFieldWrapper)}>
                <div
                  className={classNames(styles.notification, styles.errorMsg)}
                >
                  <FormattedMessage
                    id={`tabs.device.messages.errors.${resp.status}`}
                    defaultMessage="Failed to retrieve location. Check your Internet connection and try again"
                  />
                </div>
              </div>,
            );
          }
        } else {
          setAdditionalLocations([resp]);
        }

        setIsInAction(false);
      },
    };

    useImperativeHandle(ref, () => ({
      submit() {
        onCreate ? actions.onCreate() : actions.onEdit();
      },
      cleanup() {
        cleanup();
      },
    }));

    useAsyncEffect(async () => {
      setIsLoading(true);
      setAdditionalLocations([]);

      if (currentMAC) {
        setMAC(currentMAC);
      }

      if (deviceData) {
        if (!notifiableDevices.includes(deviceData.type)) {
          deviceData.location = '';
        }

        // if member's locations doesn't have current device's location we should retrieve info about it
        if (
          !locations
            .map((location: MemberLocation) => location.uuid)
            .includes(deviceData.location)
        ) {
          if (deviceData.location) {
            await actions.loadAdditionalLocations(deviceData.location);
          }
        }

        setForm(deviceData);
        setIsOtherType(
          deviceData.otherType === undefined || deviceData.otherType.length > 0,
        );
      }

      setIsLoading(false);
      return () => {
        setMAC('');
        setForm(initialFormState);
        setAdditionalLocations([]);
      };
    }, [currentMAC, deviceData, locations]);

    useEffect(() => {
      return onValidate ? onValidate(formIsValid()) : undefined;
    });

    const cleanup = () => {
      setForm(initialFormState);
      setErrors(initialErrorsState);
      setAdditionalLocations([]);

      if (onSetField) {
        onSetField('type', '');
        onSetField('mac', '');
        onSetField('name', '');
        onSetField('location', '');
        onSetField('otherType', '');
      }
    };

    const updateField = useCallback(
      (name: string, val: string) => {
        if (name === 'type') {
          if (notifiableDevices.includes(val)) {
            setForm((prev) => {
              if (prev.location) {
                return prev;
              }
              if (locations.length === 1) {
                return { ...prev, location: locations[0].uuid };
              }
              return prev;
            });
          } else {
            setForm((prev) => ({ ...prev, location: '' }));
          }
        }

        validateField(name, val);
        setForm((prev) => ({ ...prev, [name]: val }));

        if (onSetField) {
          onSetField(name, val);
        }
      },
      [onSetField],
    );

    const validateField = (name: string, val: string) => {
      if (!val) {
        setErrors((prev) => ({ ...prev, [name]: true }));
      } else {
        if (name === 'mac') {
          setErrors((prev) => ({ ...prev, [name]: !validateMAC(val) }));
          return;
        }

        setErrors((prev) => ({ ...prev, [name]: false }));
      }
    };

    const formIsValid = (): boolean => {
      if (isInAction) {
        return false;
      }

      if (
        errors.mac ||
        errors.name ||
        errors.type ||
        errors.location ||
        (errors.otherType && isOtherType)
      ) {
        return false;
      }

      return !(
        form.mac === '' ||
        form.name === '' ||
        form.type === '' ||
        (form.location === '' && notifiableDevices.includes(form.type)) ||
        (form.location === undefined &&
          notifiableDevices.includes(form.type)) ||
        (form.otherType === '' && isOtherType) ||
        (form.otherType === undefined && isOtherType) ||
        personalNetworkDevices.includes(form.type)
      );
    };

    return (
      <>
        {isLoading && onLoad ? (
          onLoad()
        ) : (
          <>
            <div className={classNames(className, styles.textFieldWrapper)}>
              <Select
                label={
                  <label className="ray-select__label">
                    <FormattedMessage
                      id="tabs.device.form.fields.type.label"
                      defaultMessage="Device type"
                    />
                  </label>
                }
                currentItem={form.type}
                items={onCreate ? options : editingOptions}
                mapper={(deviceType: string) => (
                  <option key={deviceType} value={deviceType}>
                    {intl.formatMessage({
                      id: `tabs.device.types.${deviceType}`,
                      defaultMessage: deviceType,
                    })}
                  </option>
                )}
                onSet={(deviceType: string) => {
                  setIsOtherType(deviceType === 'Other device');
                  updateField('type', deviceType);
                }}
              />
            </div>

            {!personalNetworkDevices.includes(form.type) ? (
              <>
                <div className={classNames(className, styles.textFieldWrapper)}>
                  {isOtherType ? (
                    <Input
                      id={`other-device-type-input-${
                        onCreate ? 'create' : 'edit'
                      }`}
                      placeholder={intl.formatMessage({
                        id: 'tabs.device.form.fields.otherType.placeholder',
                        defaultMessage: "Please enter device's type",
                      })}
                      labelText={
                        <FormattedMessage
                          id="tabs.device.form.fields.otherType.label"
                          defaultMessage="Other device type"
                        />
                      }
                      maxLength={30}
                      text={form.otherType ? form.otherType : ''}
                      isError={errors.otherType}
                      onChange={(e) => updateField('otherType', e.target.value)}
                    />
                  ) : (
                    <></>
                  )}
                </div>

                <div className={classNames(className, styles.textFieldWrapper)}>
                  <Input
                    id={'mac-input'}
                    placeholder={intl.formatMessage({
                      id: 'tabs.device.form.fields.mac.placeholder',
                      defaultMessage: "Please enter device's MAC address",
                    })}
                    labelText={
                      <FormattedMessage
                        id="tabs.device.form.fields.mac.label"
                        defaultMessage="MAC address"
                      />
                    }
                    text={form.mac}
                    isError={errors.mac}
                    onChange={(e) => updateField('mac', e.target.value)}
                  />
                </div>

                <div className={classNames(className, styles.textFieldWrapper)}>
                  <Input
                    id={'name-input'}
                    placeholder={intl.formatMessage({
                      id: 'tabs.device.form.fields.name.placeholder',
                      defaultMessage: "Please enter device's name",
                    })}
                    labelText={
                      <FormattedMessage
                        id="tabs.device.form.fields.name.label"
                        defaultMessage="Device name"
                      />
                    }
                    maxLength={30}
                    isError={errors.name}
                    text={form.name}
                    onChange={(e) => updateField('name', e.target.value)}
                  />
                </div>

                {notifiableDevices.includes(form.type) &&
                [...locations, ...additionalLocations].length > 1 ? (
                  <div
                    className={classNames(className, styles.textFieldWrapper)}
                  >
                    <Select
                      label={
                        <label className="ray-select__label">
                          <FormattedMessage
                            id="tabs.device.form.fields.location.label"
                            defaultMessage="Location"
                          />
                        </label>
                      }
                      items={[...locations, ...additionalLocations].map(
                        (location) => location.full_name,
                      )}
                      currentItem={(() => {
                        const location = [
                          ...locations,
                          ...additionalLocations,
                        ].find(
                          (val: MemberLocation) => form.location === val.uuid,
                        );
                        return location ? location.full_name : form.location;
                      })()}
                      onSet={(locationName: string) => {
                        const locationUUID = [
                          ...locations,
                          ...additionalLocations,
                        ].find(
                          (val: MemberLocation) =>
                            val.full_name === locationName,
                        );
                        updateField(
                          'location',
                          locationUUID ? locationUUID.uuid : form.location,
                        );
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    );
  },
);
