import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../WifiConnect.module.scss';

export type DeviceTabDescription = {
  network_name: string;
};

export const DeviceTabDescription: React.FC<DeviceTabDescription> = ({
  network_name,
}) => {
  return (
    <div className="ray-grid__cell--span-full" style={{ marginBottom: 15 }}>
      <span>
        <FormattedMessage
          id="tabs.device.description.message"
          defaultMessage="Use {network} network to connect your:"
          values={{ network: <b>{network_name}</b> }}
        />
      </span>
      <ul className={styles.devicesList}>
        <li>
          <span>
            <FormattedMessage
              id="tabs.device.description.devices.0"
              defaultMessage="Printer"
            />
          </span>
        </li>
        <li>
          <span>
            <FormattedMessage
              id="tabs.device.description.devices.1"
              defaultMessage="Network Server / Storage"
            />
          </span>
        </li>
        <li>
          <span>
            <FormattedMessage
              id="tabs.device.description.devices.2"
              defaultMessage="Streaming device"
            />
          </span>
        </li>
        <li>
          <span>
            <FormattedMessage
              id="tabs.device.description.devices.3"
              defaultMessage="Television"
            />
          </span>
        </li>
        <li>
          <span>
            <FormattedMessage
              id="tabs.device.description.devices.4"
              defaultMessage="Other similar devices"
            />
          </span>
        </li>
      </ul>
    </div>
  );
};
