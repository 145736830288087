import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';

import { App } from './app/App';

import messages_ar from './translations/ar.json';
import messages_ca from './translations/ca.json';
import messages_csCZ from './translations/cs-CZ.json';
import messages_deDE from './translations/de-DE.json';
import messages_enUS from './translations/en-US.json';
import messages_esES from './translations/es-ES.json';
import messages_esLA from './translations/es-LA.json';
import messages_frCA from './translations/fr-CA.json';
import messages_frFR from './translations/fr-FR.json';
import messages_idID from './translations/id-ID.json';
import messages_itIT from './translations/it-IT.json';
import messages_jaJP from './translations/ja-JP.json';
import messages_koKR from './translations/ko-KR.json';
import messages_msMY from './translations/ms-MY.json';
import messages_nlNL from './translations/nl-NL.json';
import messages_plPL from './translations/pl-PL.json';
import messages_ptBR from './translations/pt-BR.json';
import messages_ruRU from './translations/ru-RU.json';
import messages_svSE from './translations/sv-SE.json';
import messages_thTH from './translations/th-TH.json';
import messages_viVN from './translations/vi-VN.json';
import messages_zhCN from './translations/zh-CN.json';
import messages_zhTW from './translations/zh-TW.json';

import '@wework/ray-core/css/ray-core.css';
import 'normalize.css';
import './index.scss';

interface Messages {
  [locale: string]: any;
}

const flatten = require('flat');
const translations: Messages = {
  ar: messages_ar,
  ca: messages_ca,
  'cs-CZ': messages_csCZ,
  cs: messages_csCZ,
  'de-DE': messages_deDE,
  de: messages_deDE,
  'en-US': messages_enUS,
  en: messages_enUS,
  'es-ES': messages_esES,
  'es-LA': messages_esLA,
  es: messages_esLA,
  'fr-CA': messages_frCA,
  'fr-FR': messages_frFR,
  fr: messages_frFR,
  'id-ID': messages_idID,
  id: messages_idID,
  'it-IT': messages_itIT,
  it: messages_itIT,
  'ja-JP': messages_jaJP,
  ja: messages_jaJP,
  'ko-KR': messages_koKR,
  ko: messages_koKR,
  'ms-MY': messages_msMY,
  ms: messages_msMY,
  'nl-NL': messages_nlNL,
  nl: messages_nlNL,
  'pl-PL': messages_plPL,
  pl: messages_plPL,
  'pt-BR': messages_ptBR,
  pt: messages_ptBR,
  'ru-RU': messages_ruRU,
  ru: messages_ruRU,
  'sv-SE': messages_svSE,
  sv: messages_svSE,
  'th-TH': messages_thTH,
  th: messages_thTH,
  'vi-VN': messages_viVN,
  vi: messages_viVN,
  'zh-CN': messages_zhCN,
  'zh-TW': messages_zhTW,
  zh: messages_zhCN,
};

const getMessages = () => {
  let localeRegion = navigator.language;
  let messages = translations[localeRegion];
  if (messages == undefined) {
    localeRegion = localeRegion.split(/[-_]/)[0];
    messages = translations[localeRegion];
  }
  if (messages == undefined) {
    messages = messages_enUS;
  }
  return flatten(messages);
};

ReactDOM.render(
  <IntlProvider locale={navigator.language} messages={getMessages()}>
    <App />
  </IntlProvider>,
  document.getElementById('root'),
);
