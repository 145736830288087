import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../WifiConnect.module.scss';

export type NavProps = {
  onLogout: () => void;
};

export const Nav: React.FC<NavProps> = ({ onLogout }) => {
  return (
    <div className={classNames('ray-grid__cell--span-full', styles.nav)}>
      <span className={styles.weworkLogo}>
        <span className={styles.logo}></span>
        <span style={{ marginTop: 8 }}>WiFi</span>
      </span>

      <button
        className={classNames(
          'ray-button ray-button--secondary ray-button--compact',
          styles.logoutBtn,
        )}
        onClick={onLogout}
      >
        <FormattedMessage id="nav.logout" defaultMessage="Log out" />
      </button>
    </div>
  );
};
