import classNames from 'classnames';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styles from '../../WifiConnect.module.scss';

type SearchInputProps = {
  id: string;
  placeholder: string;
  labelText: JSX.Element;
  maxLength?: number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const SearchInput: React.FC<SearchInputProps> = ({
  id,
  placeholder,
  labelText,
  maxLength,
  onChange,
}) => {
  return (
    <div className={classNames(`ray-text-field`, styles.input)}>
      <input
        className="ray-text-field__input"
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength}
      />

      <label className="ray-text-field__label" htmlFor={id}>
        {labelText}
      </label>
    </div>
  );
};
