import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Tab, TabList, TabPanel, useTabState } from 'reakit/Tab';
import { wrapperDiv } from '../../constants/app';
import {
  ErrorResponse,
  WeSecureWifiCredentialsInterface,
} from '../../api/wifi/client';
import { Header } from './components/Header';
import { Nav } from './components/Nav';
import { DeviceTab } from './components/Tabs/DeviceTab';
import { PersonalTab } from './components/Tabs/PersonalTab';
import styles from './WifiConnect.module.scss';
import { Footer } from './components/Footer';
import { isSupported } from '../../utils/utils';
import { template } from '../../app/unsupported-browser';
import Loader from 'react-loader-spinner';
import { FormattedMessage } from 'react-intl';
import { WarningMessage } from './components/WarningMessage';
import { CorporateTab } from './components/Tabs/CorporateTab';

const needsRTL = () => {
  const country = navigator.language.substr(0, 2);
  return country === 'ar' ? 'rtl' : 'ltr';
};

const userIsInactiveMessage = 'user_is_inactive';

const WifiConnect: React.FC = () => {
  const tab = useTabState({ selectedId: 'weworkwifi' });
  const { logout } = useAuth0();

  // we need this changes for a sending 'locations' and 'devices' tab only when we click on the device tab
  const [sendDeviceReqDetector, setSendDeviceReqDetector] = useState(false);
  const [isDeviceReqWasSent, setIsDeviceReqWasSent] = useState(false);
  const handleClickSendDeviceReq = () => {
    if (!isDeviceReqWasSent) {
      setSendDeviceReqDetector((oldState) => !oldState);
      setIsDeviceReqWasSent(true);
    }
  };

  const [sendCorporateReqDetector, setCorporateReqDetector] = useState(false);
  const [isCorporateReqWasSent, setIsCorporateWasSent] = useState(false);
  const handleClickSendCorporateReq = () => {
    if (!isCorporateReqWasSent) {
      setCorporateReqDetector((oldState) => !oldState);
      setIsCorporateWasSent(true);
    }
  };

  const [credentials, setCredentials] = useState<
    WeSecureWifiCredentialsInterface
  >({
    username: '',
    password: '',
    network_name: '',
    device_network_name: '',
    shared_key: '',
    wifi_corp_devices: false,
  });

  const [errorMsg, setErrorMsg] = useState<ErrorResponse>();
  return (
    <main
      dir={needsRTL()}
      className={classNames('ray-page-container', styles.wifiConnectContainer)}
    >
      <WarningMessage />
      <div className="ray-grid">
        <Nav onLogout={logout} />

        {isSupported() ? (
          <>
            {errorMsg ? (
              errorMsg.status === userIsInactiveMessage ? (
                <div className={classNames('ray-grid__cell--span-full')}>
                  <div className={classNames(wrapperDiv, styles.centered)}>
                    <div className={styles.errorPermissions}>
                      <h1 className="ray-text--h1">
                        <FormattedMessage
                          id="header.userInactivityMessage.title"
                          defaultMessage="Access denied"
                        />
                      </h1>
                      <span className="ray-text--body-large">
                        <FormattedMessage
                          id="header.userInactivityMessage.description"
                          defaultMessage="Your membership with WeWork is no longer active. For assistance, please reach out to {email}."
                          values={{
                            email: (
                              <a href="mailto:help@wework.com">
                                help@wework.com
                              </a>
                            ),
                          }}
                        />
                      </span>
                      <br />

                      <button
                        className={styles.logoutLink}
                        onClick={() => logout()}
                      >
                        <FormattedMessage
                          id="nav.logout"
                          defaultMessage="Log out"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <Header error_msg={errorMsg} />
              )
            ) : (
              <>
                <Header />
                <div className={classNames('ray-grid__cell--span-full')}>
                  <div className={classNames(wrapperDiv, styles.centered)}>
                    {credentials.username ? (
                      <TabList
                        {...tab}
                        aria-label="Instructions Tabs"
                        className={classNames(
                          'ray-tabs ray-grid__cell--span-full mainTabs',
                          credentials.wifi_corp_devices
                            ? styles.corpTabList
                            : styles.tabList,
                        )}
                      >
                        <Tab
                          {...tab}
                          id="weworkwifi"
                          className="ray-tabs__item"
                        >
                          {credentials.network_name}
                        </Tab>
                        <Tab
                          onClick={handleClickSendDeviceReq}
                          {...tab}
                          id="device"
                          className="ray-tabs__item"
                        >
                          {credentials.device_network_name}
                        </Tab>
                        {credentials.wifi_corp_devices ? (
                          <Tab
                            onClick={handleClickSendCorporateReq}
                            {...tab}
                            id="corporate"
                            className="ray-tabs__item"
                          >
                            {'Corporate'}
                          </Tab>
                        ) : (
                          <></>
                        )}
                      </TabList>
                    ) : (
                      <div className={styles.formLoader}>
                        <Loader
                          type="Oval"
                          color="#00F"
                          height={40}
                          width={40}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={classNames(
                    'ray-grid__cell--span-full',
                    styles.tabPanels,
                  )}
                >
                  <div>
                    <TabPanel {...tab} id="weworkwifi">
                      <PersonalTab
                        onAuth={setCredentials}
                        onError={setErrorMsg}
                      />
                    </TabPanel>

                    <TabPanel {...tab} id="device">
                      <DeviceTab
                        credentials={credentials}
                        sendDeviceReqDetector={sendDeviceReqDetector}
                      />
                    </TabPanel>
                    <TabPanel {...tab} id="corporate">
                      <CorporateTab
                        credentials={credentials}
                        sendCorporateReqDetector={sendCorporateReqDetector}
                      />
                    </TabPanel>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <div className={classNames('ray-grid__cell--span-full')}>
            <div
              className="ray-grid__cell--span-4 ray-grid__cell--push-4-desktop ray-grid__cell--push-1-tablet ray-grid__cell--span-6-tablet ray-grid__cell--span-12-phone"
              dangerouslySetInnerHTML={{ __html: template }}
            ></div>
          </div>
        )}
      </div>

      <Footer />
    </main>
  );
};

export default WifiConnect;
