import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import styles from '../../WifiConnect.module.scss';

export type SelectProps = {
  label: JSX.Element;
  currentItem?: string;
  items: string[];
  isError?: boolean;
  mapper?: (currentItem: string) => void;
  onSet: (currentItem: string) => void;
};

export const Select: React.FC<SelectProps> = ({
  label,
  currentItem,
  items,
  isError,
  mapper,
  onSet,
}) => {
  const [item, setItem] = useState<string>('');
  const defaultMapper = (item: string) => (
    <option key={item} value={item}>
      {item}
    </option>
  );
  const mapperFn = mapper ? mapper : defaultMapper;

  useEffect(() => {
    setItem(currentItem ? currentItem : '');
    return () => {
      setItem('');
    };
  }, [currentItem]);

  const isDefaultMapperUsed = (): boolean => mapperFn === defaultMapper;

  const isEmpty = (): boolean => items.length === 0;

  const isOneItemAvailable = (): boolean => items.length === 1;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setItem(e.target.value);
      onSet(e.target.value);
    },
    [onSet],
  );

  return (
    <div
      className={classNames(
        `ray-select ${
          item || (isDefaultMapperUsed() && isOneItemAvailable())
            ? 'ray-select--has-value'
            : ''
        } ${isError ? 'ray-select--error' : ''}`,
        styles.input,
      )}
    >
      <select
        className={classNames('ray-select__input', styles.raySelectInput)}
        value={item}
        onChange={handleChange}
        disabled={isEmpty() || isOneItemAvailable()}
      >
        {isDefaultMapperUsed() && isOneItemAvailable() ? (
          <></>
        ) : (
          <option disabled />
        )}

        {items.find((val: string) => val === currentItem)
          ? items.map(mapperFn)
          : currentItem
          ? [...items, currentItem].map(mapperFn)
          : items.map(mapperFn)}
      </select>

      {label}
    </div>
  );
};
