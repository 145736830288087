export const second = 1000;
export const shortTimeout = second * 10; // 10 s
export const timeout = second * 60; // 1 min
export const maxDevices = 5;
export const formInputStyles =
  'ray-grid__cell--span-4 ray-grid__cell--push-4-desktop ray-grid__cell--push-1-tablet ray-grid__cell--span-6-tablet ray-grid__cell--span-12-phone';

export const wrapperDiv =
  'ray-grid__cell--span-4 ray-grid__cell--push-4-desktop ray-grid__cell--push-1-tablet ray-grid__cell--span-6-tablet ray-grid__cell--span-12-phone';

export const personalNetworkDevices = [
  'Personal computer or laptop',
  'Smart Tablet',
  'Smart Phone',
];

export const notifiableDevices = ['Printer', 'Network Server / Storage'];

export const options = [
  'Printer',
  'Network Server / Storage',
  'Streaming Device',
  'Television',
  'Personal computer or laptop',
  'Smart Tablet',
  'Smart Phone',
  'Other device',
];

export const editingOptions = [
  'Printer',
  'Network Server / Storage',
  'Streaming Device',
  'Television',
  'Other device',
];

export const corporateDeviceTypeOptions = [
  'iPad',
  'iPhone',
  'iPod',
  'CUPs',
  'sensor',
  'timeclock',
  'other',
];

export const corporateRegionsOptions = ['usc', 'latam', 'emea', 'apac'];

export const amountOfLastDigitsForName = 6;
