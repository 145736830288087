import React from 'react';
import styles from '../WifiConnect.module.scss';
import { AppConfig } from '../../../constants/config';

export const WarningMessage: React.FC = () => {
  return AppConfig.showWarningMessage ? (
    <div className={styles.notification}>
      Planned maintenance on{' '}
      <strong>Saturday October 26 from 12pm - 6pm ET</strong> with intermittent
      wireless connectivity issues on WeWorkWiFi and WeWorkDevice. While this
      website remains operational during this time no changes will be allowed.
    </div>
  ) : (
    <></>
  );
};
