import React from 'react';
import styles from './Link.module.scss';
import classNames from 'classnames';

interface LinkProps {
  title: JSX.Element;
  action: Function;
}

export const Link: React.FC<LinkProps> = ({ title, action }) => {
  return (
    <a
      className={classNames(styles.link, styles.rightLink)}
      onClick={() => action()}
    >
      {title}
    </a>
  );
};
