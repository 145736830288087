import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { betterWithAuthenticationRequired } from '@wework/we-auth-react';

import { ROUTES } from '../constants/routes';
import WifiConnect from '../scenes/WifiConnect/WifiConnect';
import { history } from './history';

export const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <Route exact path={ROUTES.ROOT} component={WifiConnect} />
      <Redirect to={ROUTES.ROOT} />
    </Switch>
  </Router>
);

export const AuthenticatedAppWrapper = betterWithAuthenticationRequired(
  AppRouter,
);
