import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Modal, ModalInterface } from '../Modal';
import Loader from 'react-loader-spinner';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import styles from './ReportForm.module.scss';
import globalStyles from '../../WifiConnect.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { slackService } from '../../../../api/slack/service';
import { AppConfig } from '../../../../constants/config';
import { Block } from '../Block/Block';
import { timeout } from '../../../../constants/app';

const minReportLength = 10;

export interface ReportFormInterface {
  show(): void;
  hide(): void;
}

interface ReportFormProps {
  email: string;
  password: string;
  onSuccess: Function;
  onCancel?: Function;
}

export const ReportForm = forwardRef<ReportFormInterface, ReportFormProps>(
  ({ email, password, onSuccess, onCancel }, ref) => {
    const modalRef = useRef<ModalInterface>(null);
    const intl = useIntl();
    const { getAccessTokenSilently } = useAuth0();

    const [formState, setFormState] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);
    const [isReporting, setIsReporting] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      show() {
        if (modalRef.current) {
          modalRef.current.show();
        }
      },
      hide() {
        if (modalRef.current) {
          modalRef.current.hide();
        }
      },
    }));

    useEffect(() => cleanup(), []);

    const onSubmit = async () => {
      setIsReporting(true);

      if (modalRef.current) {
        modalRef.current.toggleOK();
        modalRef.current.toggleCancel();
      }

      try {
        const token = await getAccessTokenSilently();
        await slackService(
          AppConfig.appEnv,
          token,
        ).reportAboutOffensivePassword(formState);

        cleanup();

        if (onSuccess) {
          onSuccess();
        }
        if (modalRef.current) {
          modalRef.current.hide();
        }
      } catch (e) {
        if (modalRef.current) {
          modalRef.current.enableOK();
        }

        setIsError(true);
        setTimeout(() => setIsError(false), timeout);
      }

      setIsReporting(false);

      if (modalRef.current) {
        modalRef.current.enableCancel();
      }
    };

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setFormState(e.target.value);

      if (modalRef.current) {
        if (e.target.value.length >= minReportLength) {
          modalRef.current.enableOK();
        } else {
          modalRef.current.disableOK();
        }
      }
    };

    const cleanup = () => {
      if (modalRef.current) {
        modalRef.current.disableOK();
      }

      setFormState('');
      setIsError(false);
    };

    return (
      <Modal
        ref={modalRef}
        title={
          <FormattedMessage
            id="tabs.personal.reportForm.form.title"
            defaultMessage="Report about offensive password"
          />
        }
        onOK={onSubmit}
        onCancel={() => {
          if (onCancel) {
            onCancel();
          }

          cleanup();
        }}
        okText={
          isReporting ? (
            <Loader type="Oval" color="#FFF" height={13} width={13} />
          ) : (
            <FormattedMessage
              id="tabs.personal.reportForm.form.action"
              defaultMessage="Send"
            />
          )
        }
        isSelfClosable={false}
      >
        {isError ? (
          <Block>
            <div
              className={classNames(
                globalStyles.notification,
                globalStyles.errorMsgInReportModal,
              )}
            >
              <FormattedMessage
                id={`tabs.personal.reportForm.error`}
                defaultMessage="Internal error. Check your Internet connection and try again"
              />
            </div>
          </Block>
        ) : (
          <></>
        )}

        <FormattedMessage
          id="tabs.personal.reportForm.form.description"
          defaultMessage="If you have found your password offensive, please submit the form below so that we can act on it. Once you submit, you can click the 'Reset credentials' to generate a new password."
        />

        <div className={classNames('ray-text-area', styles.textarea)}>
          <textarea
            rows={4}
            className="ray-text-area__input"
            id="reason"
            placeholder={intl.formatMessage({
              id: 'tabs.personal.reportForm.form.placeholder',
              defaultMessage: 'Minimum 10 characters',
            })}
            onChange={onChange}
            value={formState}
          />
          <label className="ray-text-area__label" htmlFor="reason">
            <FormattedMessage
              id="tabs.personal.reportForm.form.label"
              defaultMessage="Your reason"
            />
          </label>
        </div>
      </Modal>
    );
  },
);
