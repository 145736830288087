import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../WifiConnect.module.scss';
import { ErrorResponse } from '../../../api/wifi/client';

export type HeaderProps = {
  error_msg?: ErrorResponse;
};

export const Header: React.FC<HeaderProps> = ({ error_msg }) => {
  return (
    <div className="ray-grid__cell--span-full">
      <div
        className={classNames(
          'ray-grid__cell--span-4 ray-grid__cell--push-1-tablet ray-grid__cell--span-6-tablet ray-grid__cell--span-12-phone',
          styles.middleHeader,
        )}
      >
        <h2
          className={classNames('ray-grid__cell--span-full', styles.pageTitle)}
        >
          <FormattedMessage
            id="header.title"
            defaultMessage="Connect to WiFi"
          />
        </h2>

        {error_msg ? (
          <div
            className={classNames(
              styles.errorMessage,
              'ray-grid__cell--span-full',
            )}
          >
            <FormattedMessage
              id={`tabs.device.messages.errors.${error_msg.status}`}
              defaultMessage="Internal server error. Please contact WeWork Community People."
            />
            <br />
            <br />

            {error_msg.message ? (
              <>
                <FormattedMessage
                  id="header.details"
                  defaultMessage="Problem description"
                />

                {': '}
                {error_msg.message}
              </>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
