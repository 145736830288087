import React, {
  ChangeEvent,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import { shortTimeout } from '../../../../constants/app';
import styles from '../../WifiConnect.module.scss';
import { Input } from './Input';

export type CopyableInputProps = {
  id: string;
  text: string;
  placeholder: string;
  labelText: JSX.Element;
  isReadonly?: boolean;
  isError?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onAction?: () => void;
};

export interface CopyableInputInterface {
  resetState(): void;
}

export const CopyableInput = forwardRef<
  CopyableInputInterface,
  CopyableInputProps
>(
  (
    {
      id,
      text,
      placeholder,
      labelText,
      isReadonly,
      isError,
      onChange,
      onAction,
    },
    ref,
  ) => {
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [val, setVal] = useState<string>('');
    const [timer, setTimer] = useState<number>();

    useEffect(() => {
      setVal(text);
    }, [text]);

    useImperativeHandle(ref, () => ({
      resetState() {
        setIsCopied(false);
      },
    }));

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setVal(event.target.value);

        if (onChange) {
          onChange(event);
        }
      },
      [onChange],
    );

    const handleCopy = useCallback(() => {
      if (onAction) {
        onAction();
      }

      setIsCopied(true);
      clearTimeout(timer);

      setTimer(
        (setTimeout(
          () => setIsCopied(false),
          shortTimeout,
        ) as unknown) as number,
      );
    }, [onAction, timer]);

    return (
      <Input
        id={id}
        text={val}
        placeholder={placeholder}
        isReadonly={isReadonly}
        labelText={labelText}
        isError={isError}
        onChange={handleChange}
      >
        <CopyToClipboard text={val} onCopy={handleCopy}>
          <a className={styles.actionBtn}>
            {isCopied ? (
              <FormattedMessage
                id="tabs.personal.form.actions.copied"
                defaultMessage="Copied"
              />
            ) : (
              <FormattedMessage
                id="tabs.personal.form.actions.copy"
                defaultMessage="Copy"
              />
            )}
          </a>
        </CopyToClipboard>
      </Input>
    );
  },
);
