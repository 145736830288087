import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../WifiConnect.module.scss';
import { wrapperDiv } from '../../../constants/app';

export const Footer = () => {
  return (
    <footer className={classNames('site-footer', styles.siteFooter)}>
      <div className={classNames('ray-grid', styles.footer)}>
        <div className="ray-grid__cell--span-full">
          <div
            className={classNames(
              'ray-grid__cell--span-6 ray-grid__cell--push-3-desktop ray-grid__cell--push-1-tablet ray-grid__cell--span-6-tablet ray-grid__cell--span-12-phone',
              styles.centered,
            )}
          >
            <div className="ray-grid" style={{ margin: '0 8px' }}>
              <div className="ray-grid__cell--span-4-desktop ray-grid__cell--span-8-tablet">
                <div className={styles.siteFooter__contact}>
                  <a href="tel:(646) 389-3922">(646) 389-3922</a>
                  <address>
                    WeWork Companies LLC
                    <br />
                    71 5th Avenue
                    <br />
                    2nd floor
                    <br />
                    New York, NY 10003
                  </address>
                </div>
              </div>
              <div className="ray-grid__cell--span-8-desktop ray-grid__cell--span-8-tablet">
                <div className={styles.siteFooter__legal_container}>
                  <div className={styles.legal_text_wrapper}>
                    <ul className={styles.siteFooter__legal_links}>
                      <li className={styles.siteFooter__legal_link_item}>
                        <a href="https://www.wework.com/legal/global-privacy-policy">
                          <FormattedMessage
                            id="footer.privacy"
                            defaultMessage="Privacy"
                          />
                        </a>
                      </li>
                      <li className={styles.siteFooter__legal_link_item}>
                        <a href="https://www.wework.com/legal/terms-of-service">
                          <FormattedMessage
                            id="footer.terms"
                            defaultMessage="Terms"
                          />
                        </a>
                      </li>
                      <li className={styles.siteFooter__legal_link_item}>
                        <a href="https://www.wework.com/legal/cookie-policy">
                          <FormattedMessage
                            id="footer.cookies"
                            defaultMessage="Cookies"
                          />
                        </a>
                      </li>
                      <li className={styles.siteFooter__legal_link_item}>
                        <a href="https://www.wework.com/legal/accessibility">
                          <FormattedMessage
                            id="footer.accessibility"
                            defaultMessage="Accessibility"
                          />
                        </a>
                      </li>
                    </ul>
                    <div className={styles.siteFooter__legal_copyright}>
                      Copyright © {new Date().getFullYear()} WeWork Companies
                      Inc.&nbsp;
                      <br className="only-visible-in-phone" />
                      <FormattedMessage
                        id="footer.rights"
                        defaultMessage="All rights reserved"
                      />
                    </div>
                  </div>
                  <div
                    className={styles.siteFooter__we_logo}
                    title="The We Company logo"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
