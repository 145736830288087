import classNames from 'classnames';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styles from '../../WifiConnect.module.scss';

type InputProps = {
  id: string;
  text: string;
  placeholder: string;
  labelText: JSX.Element;
  maxLength?: number;
  isReadonly?: boolean;
  isError?: boolean;
  children?: React.ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
};

export const Input: React.FC<InputProps> = ({
  id,
  text,
  placeholder,
  labelText,
  maxLength,
  isReadonly,
  isError,
  children,
  onChange,
  isDisabled,
}) => {
  const [val, setVal] = useState<string>('');

  useEffect(() => {
    setVal(text);
    return () => {
      setVal('');
    };
  }, [text]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setVal(e.target.value);

      if (typeof onChange === 'function') {
        onChange(e);
      }
    },
    [onChange],
  );

  return (
    <div
      className={classNames(
        `ray-text-field ${isError ? 'ray-text-field--error' : ''}`,
        styles.input,
      )}
    >
      <input
        className="ray-text-field__input"
        id={id}
        type="text"
        placeholder={placeholder}
        value={val}
        onChange={handleChange}
        readOnly={isReadonly}
        maxLength={maxLength}
        disabled={isDisabled}
      />

      <label className="ray-text-field__label" htmlFor={id}>
        {labelText}
      </label>

      {children}
    </div>
  );
};
