// How to import environment specific variables
// https://github.com/TypeStrong/ts-loader/issues/37#issuecomment-381375624
declare const AUTH0_CLIENT_ID: string;
declare const AUTH0_DOMAIN: string;
declare const APP_ENV: string;
declare const SHOW_WARNING_MESSAGE: boolean;

export const Auth0Config = {
  clientID: process.env.AUTH0_CLIENT_ID || AUTH0_CLIENT_ID,
  domain: process.env.AUTH0_DOMAIN || AUTH0_DOMAIN,
};

export const AppConfig = {
  appEnv: process.env.APP_ENV || APP_ENV,
  showWarningMessage:
    process.env.SHOW_WARNING_MESSAGE === 'true' || SHOW_WARNING_MESSAGE,
};
