import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from '../../WifiConnect.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';

type AdditionalInfoProps = {
  currentItem: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
};

export const AdditionalInfo: React.FC<AdditionalInfoProps> = ({
  currentItem,
  onChange,
}) => {
  const [val, setVal] = useState<string>('');

  useEffect(() => {
    setVal(currentItem);
    return () => {
      setVal('');
    };
  }, [currentItem]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setVal(e.target.value);

      if (typeof onChange === 'function') {
        onChange(e);
      }
    },
    [onChange],
  );

  return (
    <div className={classNames('ray-text-area', styles.textarea)}>
      <textarea
        rows={4}
        value={val}
        className="ray-text-area__input"
        onChange={handleChange}
      />
      <label className="ray-text-area__label" htmlFor="reason">
        <FormattedMessage
          id="tabs.corporate.form.fields.additional_info.label"
          defaultMessage="Additional Info"
        />
      </label>
    </div>
  );
};
