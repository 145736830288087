import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AppConfig } from '../../../../constants/config';
import { getDeviceClient } from '../../../../api/devices/client';
import styles from '../../WifiConnect.module.scss';
import { CorpFormState } from '../Forms/CorpForm';
import { Modal, ModalInterface } from '../Modal';
import CorporateDeviceTableRow from './CorporateDeviceTableRow';
import {
  CorporateEditForm,
  CorporateEditFormInterface,
} from '../Forms/CorporateEditForm';

export type CorpDevice = {
  mac: string;
  type: string;
  name: string;
  location_uuid: string;
  notes: string;
};

export type CorporateDeviceTable = {
  devices: CorpDevice[];
  onUpdate: () => void;
  onMessage: (msg: JSX.Element) => void;
};

export const CorporateDeviceTable: React.FC<CorporateDeviceTable> = ({
  devices,
  onUpdate,
  onMessage,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const alertRef = useRef<ModalInterface>(null);
  const editFormRef = useRef<CorporateEditFormInterface>(null);

  const [callback, setCallback] = useState<Function>();
  const [currentMAC, setCurrentMAC] = useState<string>('');
  const [currentName, setCurrentName] = useState<string>('');
  const [currentDevice, setCurrentDevice] = useState<CorpFormState>();

  const onDelete = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const resp = await getDeviceClient(AppConfig.appEnv, token).deleteDevice(
      currentMAC,
      true,
    );

    if (!resp) {
      onUpdate();
      onMessage(
        <div
          className={classNames(
            'ray-grid__cell--span-full',
            styles.textFieldWrapper,
          )}
        >
          <div
            className={classNames(
              styles.notification,
              styles.successfulNotification,
            )}
          >
            <FormattedMessage
              id="tabs.corporate.messages.afterDelete"
              defaultMessage="Device ''{device}'' was deleted!"
              values={{ device: currentName }}
            />
          </div>
        </div>,
      );
    } else {
      onMessage(
        <div
          className={classNames(
            'ray-grid__cell--span-full',
            styles.textFieldWrapper,
          )}
        >
          <div
            className={classNames(styles.notification, styles.errorMsgInTable)}
          >
            <FormattedMessage
              id={`tabs.corporate.messages.errors.${resp.status}`}
              // values={{ maxDevices: maxDevices }}
              defaultMessage="Internal error. Check your Internet connection and try again"
            />
          </div>
        </div>,
      );
    }
  }, [currentMAC, currentName, getAccessTokenSilently, onMessage, onUpdate]);

  return (
    <div className="ray-grid__cell--span-full">
      <Modal
        ref={alertRef}
        title={
          <FormattedMessage
            id="modal.headings.confirmation"
            defaultMessage="Are you sure you want to delete this device?"
          />
        }
        onOK={() => {
          onDelete();
          setCurrentMAC('');
          if (callback) {
            callback();
          }
        }}
        onCancel={() => {
          setCurrentMAC('');
          if (callback) {
            callback();
          }
        }}
      />

      <CorporateEditForm
        ref={editFormRef}
        currentMAC={currentMAC}
        deviceData={currentDevice}
        title={
          <FormattedMessage
            id="modal.headings.editFormTitle"
            defaultMessage="Edit device"
          />
        }
        onOK={() => {
          setCurrentMAC('');
          setCurrentDevice(undefined);

          if (callback) {
            callback();
          }
        }}
        onCancel={() => {
          setCurrentMAC('');
          setCurrentDevice(undefined);

          if (callback) {
            callback();
          }
        }}
        onFinished={onUpdate}
        onMessage={onMessage}
      />

      <table className="ray-table">
        <thead>
          <tr>
            <th>
              <FormattedMessage
                id="tabs.corporate.table.header.columns.0"
                defaultMessage="Name"
              />
            </th>
            <th>
              <FormattedMessage
                id="tabs.corporate.table.header.columns.1"
                defaultMessage="MAC address"
              />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {devices.map((device: CorpDevice) => (
            <CorporateDeviceTableRow
              key={device.mac}
              device={device}
              onEdit={(
                mac: string,
                device: CorpFormState,
                onCancel?: () => void,
              ) => {
                setCurrentMAC(mac);
                setCurrentDevice(device);
                setCallback(onCancel);

                if (editFormRef.current) {
                  editFormRef.current.show();
                }
              }}
              onDelete={(mac: string, name: string, onCancel?: () => void) => {
                setCurrentMAC(mac);
                setCurrentName(name);
                setCallback(onCancel);

                if (alertRef.current) {
                  alertRef.current.show();
                }
              }}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
