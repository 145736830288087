import React, { useCallback } from 'react';
import { PopoverDisclosure, usePopoverState } from 'reakit/Popover';
import { editingOptions } from '../../../../constants/app';
import styles from '../../WifiConnect.module.scss';
import { FormState } from '../Forms/Form';
import { Actions } from './Actions';
import { Device } from './DevicesTable';
import { FormattedMessage } from 'react-intl';

interface DeviceTableRowProps {
  device: Device;
  onEdit: (mac: string, device: FormState, onCancel?: () => void) => void;
  onDelete: (mac: string, name: string, onCancel?: () => void) => void;
}

const DeviceTableRow: React.FC<DeviceTableRowProps> = ({
  device,
  onEdit,
  onDelete,
}) => {
  const popover = usePopoverState({ placement: 'top-start' });

  const actions = {
    onEdit: () => {
      onEdit(device.mac, makeDeviceFormState());
    },
    onDelete: () => {
      onDelete(device.mac, device.name);
    },
  };

  const makeDeviceFormState = useCallback(
    () => ({
      mac: device.mac,
      name: device.name,
      type: editingOptions.includes(device.type) ? device.type : 'Other device',
      otherType: !editingOptions.includes(device.type) ? device.type : '',
      location: device.location_uuid,
    }),
    [device.mac, device.name, device.type, device.location_uuid],
  );

  return (
    <tr>
      <td className={styles.tableCol}>{device.name}</td>
      <td className={styles.tableColMac}>{device.mac}</td>
      <td className={styles.tableCol}>
        {editingOptions.includes(device.type) ? (
          <FormattedMessage
            id={`tabs.device.types.${device.type}`}
            defaultMessage={device.type}
          />
        ) : (
          device.type
        )}
      </td>
      <td className={styles.tableCol} style={{ verticalAlign: 'middle' }}>
        <Actions
          props={{ ...popover }}
          onEdit={actions.onEdit}
          onDelete={actions.onDelete}
        />
        <PopoverDisclosure {...popover} className={styles.menuBtn} />
      </td>
    </tr>
  );
};

export default DeviceTableRow;
