import React, { useCallback } from 'react';
import { PopoverDisclosure, usePopoverState } from 'reakit/Popover';
import {
  corporateDeviceTypeOptions,
  corporateRegionsOptions,
  editingOptions,
} from '../../../../constants/app';
import styles from '../../WifiConnect.module.scss';
import { Actions } from './Actions';
import { FormattedMessage } from 'react-intl';
import { CorpFormState } from '../Forms/CorpForm';
import { CorpDevice } from './CorporateDeviceTable';

interface CorporateDeviceTableRowProps {
  device: CorpDevice;
  onEdit: (mac: string, device: CorpFormState, onCancel?: () => void) => void;
  onDelete: (mac: string, name: string, onCancel?: () => void) => void;
}

const CorporateDeviceTableRow: React.FC<CorporateDeviceTableRowProps> = ({
  device,
  onEdit,
  onDelete,
}) => {
  const popover = usePopoverState({ placement: 'top-start' });

  const actions = {
    onEdit: () => {
      onEdit(device.mac, makeDeviceFormState());
    },
    onDelete: () => {
      onDelete(device.mac, device.name);
    },
  };

  const getRegion = (deviceName: string): string => {
    const nameByPart = deviceName.split('-');
    return nameByPart[0];
  };

  const getType = (deviceName: string): string => {
    const nameByPart = deviceName.split('-');
    return nameByPart[1];
  };

  const makeDeviceFormState = useCallback(
    () => ({
      mac: device.mac,
      name: device.name,
      type: corporateDeviceTypeOptions.includes(getType(device.name))
        ? getType(device.name)
        : 'No type',
      otherType: !editingOptions.includes(device.type) ? device.type : '',
      location: device.location_uuid,
      region: corporateRegionsOptions.includes(getRegion(device.name))
        ? getRegion(device.name)
        : 'No region',
      notes: device.notes,
    }),
    [device.mac, device.name, device.type, device.location_uuid, device.notes],
  );

  return (
    <tr>
      <td className={styles.corpTableCol}>{device.name}</td>
      <td className={styles.corpTableCol}>{device.mac}</td>
      <td className={styles.corpTableCol} style={{ verticalAlign: 'middle' }}>
        <Actions
          props={{ ...popover }}
          onEdit={actions.onEdit}
          onDelete={actions.onDelete}
        />
        <PopoverDisclosure {...popover} className={styles.menuBtn} />
      </td>
    </tr>
  );
};

export default CorporateDeviceTableRow;
