import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { maxDevices, timeout } from '../../../../constants/app';
import { AppConfig } from '../../../../constants/config';
import { getDeviceClient } from '../../../../api/devices/client';
import { DeviceResponse } from '../../../../api/devices/types';
import { WeSecureWifiCredentialsInterface } from '../../../../api/wifi/client';
import styles from '../../WifiConnect.module.scss';
import { DeviceTabDescription } from '../Description/DeviceTabDescription';
import CreationForm from '../Forms/CreationForm';
import { PasswordField } from '../PasswordField';
import { DevicesTable } from '../Table/DevicesTable';
import useAsyncEffect from 'use-async-effect';
import {
  getLocationsClient,
  MemberLocation,
} from '../../../../api/locations/client';
import Loader from 'react-loader-spinner';

type DeviceTabProps = {
  credentials: WeSecureWifiCredentialsInterface;
  sendDeviceReqDetector: boolean;
};

type Message = {
  error?: JSX.Element;
  message?: JSX.Element;
};

export const DeviceTab: React.FC<DeviceTabProps> = ({
  credentials,
  sendDeviceReqDetector,
}) => {
  const [formMessage, setFormMessage] = useState<Message>({});
  // we need this changes for a sending 'locations' and 'devices' tab only when we click on the device tab
  const [counter, setCounter] = useState(0);
  const [tableMessage, setTableMessage] = useState<Message>({});
  const [devices, setDevices] = useState<DeviceResponse[]>([]);
  const [locations, setLocations] = useState<MemberLocation[]>([]);
  const [timer, setTimer] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);

  const { getAccessTokenSilently } = useAuth0();

  useAsyncEffect(async () => {
    // we need this changes for a sending 'locations' and 'devices' tab only when we click on the device tab
    if (counter !== 0) {
      await Promise.all([loadLocations(), loadDevices()]);
      return () => clearTimeout(timer ? timeout : undefined);
    }
    setCounter((oldValue) => oldValue + 1);
  }, [sendDeviceReqDetector]);

  const loadDevices = async () => {
    const token = await getAccessTokenSilently();
    const pageSize = 100;

    let devices = [];
    let isStart = true;
    let newDevices: DeviceResponse[] = [];
    let nextOffset = 0;

    while (isStart || newDevices.length === pageSize) {
      const resp = await getDeviceClient(
        AppConfig.appEnv,
        token,
      ).getListOfDevices(pageSize, nextOffset);

      if ('devices' in resp) {
        devices.push(...resp.devices);
        newDevices = resp.devices;
        nextOffset = resp.next_offset;
        isStart = false;
      } else {
        isStart = false;
        break;
      }
    }

    setDevices(devices);
    setLoading(false);
  };

  const loadLocations = async () => {
    const token = await getAccessTokenSilently();
    const resp = await getLocationsClient(AppConfig.appEnv).getLocations(token);

    if ('status' in resp) {
      setMessage(
        <div
          className={classNames(
            'ray-grid__cell--span-full',
            styles.textFieldWrapper,
          )}
        >
          <div className={classNames(styles.notification, styles.errorMsg)}>
            <FormattedMessage
              id={`tabs.device.messages.errors.${resp.status}`}
              defaultMessage="Failed to retrieve locations. Check your Internet connection and try again"
            />
          </div>
        </div>,
        'error',
        setFormMessage,
      );
    } else {
      setLocations(resp);
    }
  };

  const setMessage = useCallback(
    (
      msg: JSX.Element,
      type: string,
      stateFunc: React.Dispatch<React.SetStateAction<Message>>,
    ) => {
      setFormMessage({});
      setTableMessage({});
      stateFunc({ [type]: msg });

      clearTimeout(timer);
      setTimer(
        (setTimeout(
          () => stateFunc((prev) => ({ ...prev, [type]: undefined })),
          timeout,
        ) as unknown) as number,
      );
    },
    [timer],
  );

  return (
    <div
      className={classNames(
        'ray-grid__cell--span-4 ray-grid__cell--push-4-desktop ray-grid__cell--push-1-tablet ray-grid__cell--span-6-tablet ray-grid__cell--span-12-phone',
        styles.tabContent,
      )}
    >
      <DeviceTabDescription network_name={credentials.device_network_name} />

      {devices.length >= maxDevices ? (
        <div
          className={classNames(
            'ray-grid__cell--span-full',
            styles.notification,
          )}
        >
          <FormattedMessage
            id="tabs.device.messages.info.maxDevices"
            defaultMessage="Only {maxDevices} devices are allowed at the time. Please remove one of your devices if you wish to add new one."
            values={{ maxDevices: maxDevices }}
          />
        </div>
      ) : (
        <div className={'ray-grid__cell--span-full'}>
          <div className={styles.mediumTitle}>
            <h3>
              <FormattedMessage
                id="tabs.device.form.title"
                defaultMessage="Add a device (up to {maxDevices})"
                values={{ maxDevices: maxDevices }}
              />
            </h3>
          </div>
        </div>
      )}

      {formMessage.error}

      <CreationForm
        className="ray-grid__cell--span-full"
        locations={locations}
        deviceQuantity={devices.length}
        onCreate={() => loadDevices()}
        credentials={credentials}
        onSuccessMessage={(msg: JSX.Element) =>
          setMessage(msg, 'message', setFormMessage)
        }
        onErrorMessage={(msg: JSX.Element) =>
          setMessage(msg, 'error', setFormMessage)
        }
      />

      {formMessage.message}

      <div className="ray-grid__cell--span-full">
        <div className={styles.mediumTitle} style={{ marginBottom: 8 }}>
          <h3 style={{ marginBottom: '6px' }}>
            <FormattedMessage
              id="tabs.device.table.header.title"
              defaultMessage="Your registered devices"
            />
          </h3>
          <span className={styles.subtitle}>
            <FormattedMessage
              id="tabs.device.table.header.subtitle"
              defaultMessage="Maximum of {maxDevices} devices"
              values={{ maxDevices: maxDevices }}
            />
          </span>
        </div>
      </div>

      {tableMessage.message}
      {loading ? (
        <div className={styles.formLoader}>
          <Loader type="Oval" color="#00F" height={40} width={40} />
        </div>
      ) : devices.length === 0 ? (
        <div
          className={classNames(
            'ray-grid__cell--span-full',
            styles.textFieldWrapper,
          )}
        >
          <div className={styles.notification}>
            <FormattedMessage
              id="tabs.device.messages.info.noDevices"
              defaultMessage="Please use the form above to add your first device."
            />
          </div>
        </div>
      ) : (
        <DevicesTable
          devices={devices}
          locations={locations}
          onUpdate={loadDevices}
          onMessage={(msg: JSX.Element) =>
            setMessage(msg, 'message', setTableMessage)
          }
        />
      )}

      <div className="ray-grid__cell--span-full">
        <div className={styles.mediumTitle} style={{ marginTop: 46 }}>
          <h3>
            <FormattedMessage
              id="tabs.device.instructions.title"
              defaultMessage="Setup Instructions"
            />
          </h3>
        </div>
        <ol>
          <li>
            <span className="listItem">
              <FormattedMessage
                id="tabs.device.instructions.tabs.0"
                defaultMessage="Add your device using the form above."
              />
            </span>
          </li>
          <li>
            <span className="listItem">
              <FormattedMessage
                id="tabs.device.instructions.tabs.1"
                defaultMessage="Please wait 1 minute before connecting your device to ''{network}'' network in order for it to be added across all systems."
                values={{ network: credentials.device_network_name }}
              />
            </span>
          </li>
          <li>
            <span className="listItem">
              <FormattedMessage
                id="tabs.device.instructions.tabs.2"
                defaultMessage="Select ''{network}'' network."
                values={{ network: <b>{credentials.device_network_name}</b> }}
              />
            </span>
          </li>
          <li>
            <span className="listItem">
              <FormattedMessage
                id="tabs.device.instructions.tabs.3"
                defaultMessage="Type below password as a shared key when connecting your device to the internet."
              />
            </span>
          </li>
          <li>
            <span className="listItem">
              <FormattedMessage
                id="tabs.device.instructions.tabs.4"
                defaultMessage="Password:"
              />
              <PasswordField shared_key={credentials.shared_key} />
            </span>
          </li>
          <li>
            <span className="listItem">
              <FormattedMessage
                id="tabs.device.instructions.tabs.5"
                defaultMessage="Member-provided printers will need to be added/installed via their IP address after being registered on the ''{network}'' network. This is because ''{network}'' is a different Network/Subnet than the ''{network_name}'' or WeWork wired networks."
                values={{
                  network: credentials.device_network_name,
                  network_name: credentials.network_name,
                }}
              />
            </span>
          </li>
        </ol>
      </div>
    </div>
  );
};
