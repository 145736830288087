import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import React, { useState, useRef, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Loader from 'react-loader-spinner';
import useAsyncEffect from 'use-async-effect';
import { timeout } from '../../../../constants/app';
import { AppConfig } from '../../../../constants/config';
import {
  ErrorResponse,
  getWifiClient,
  WeSecureWifiCredentialsInterface,
} from '../../../../api/wifi/client';
import styles from '../../WifiConnect.module.scss';
import { InstructionsAccordion } from '../Accordion';
import { PersonalTabDescription } from '../Description/PersonalTabDescription';
import { CopyableInput, CopyableInputInterface } from '../Input/CopyableInput';
import { Link } from '../Link/Link';
import { Block } from '../Block/Block';
import { ReportForm, ReportFormInterface } from '../Forms/ReportForm';

export type PersonalTabProps = {
  onAuth: (credentials: WeSecureWifiCredentialsInterface) => void;
  onError: (err: ErrorResponse) => void;
};

export const PersonalTab: React.FC<PersonalTabProps> = ({
  onAuth,
  onError,
}) => {
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const [isRecentlyReseted, setIsRecentlyReseted] = useState<boolean>(false);
  const [isReportSent, setIsReportSent] = useState<boolean>(false);

  const { getAccessTokenSilently } = useAuth0();
  const intl = useIntl();

  const usernameRef = useRef<CopyableInputInterface>(null);
  const passwordRef = useRef<CopyableInputInterface>(null);
  const reportModalRef = useRef<ReportFormInterface>(null);

  const [credentials, setCredentials] = useState<
    WeSecureWifiCredentialsInterface
  >({
    username: '',
    password: '',
    network_name: '',
    device_network_name: '',
    shared_key: '',
    wifi_corp_devices: false,
  });

  useAsyncEffect(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const creds = await getWifiClient(AppConfig.appEnv).getWifiCredentials(
        accessToken,
      );

      if ('status' in creds) {
        throw creds;
      }

      setCredentials(creds);
      onAuth(creds);
    } catch (error) {
      setCredentials({
        username: '',
        password: '',
        network_name: '',
        device_network_name: '',
        shared_key: '',
        wifi_corp_devices: false,
      });

      onError(error);
    }
  }, []);

  const resetPassword = useCallback(async () => {
    setIsResetting(true);
    let creds: WeSecureWifiCredentialsInterface | ErrorResponse;

    try {
      const accessToken = await getAccessTokenSilently();
      creds = await getWifiClient(AppConfig.appEnv).resetWifiCredentials(
        accessToken,
      );

      if ('status' in creds) {
        throw creds;
      }
    } catch (error) {
      setCredentials({
        username: '',
        password: '',
        network_name: '',
        device_network_name: '',
        shared_key: '',
        wifi_corp_devices: false,
      });

      onError(error);
    }

    setTimeout(() => {
      setIsResetting(false);

      if ('username' in creds) {
        setCredentials(creds);
        onAuth(creds);
      }

      setIsRecentlyReseted(true);
    }, 500);

    setTimeout(() => setIsRecentlyReseted(false), timeout);
  }, [getAccessTokenSilently, onAuth, onError]);

  const resetFormState = useCallback(() => {
    if (usernameRef.current) {
      usernameRef.current.resetState();
    }
    if (passwordRef.current) {
      passwordRef.current.resetState();
    }
  }, []);

  return (
    <div
      className={classNames(
        'ray-grid__cell--span-4 ray-grid__cell--push-4-desktop ray-grid__cell--push-1-tablet ray-grid__cell--span-6-tablet ray-grid__cell--span-12-phone',
        styles.tabContent,
      )}
    >
      <PersonalTabDescription network_name={credentials.network_name} />
      <ReportForm
        ref={reportModalRef}
        email={credentials.username}
        password={credentials.password}
        onSuccess={() => {
          setIsReportSent(true);
          setTimeout(() => setIsReportSent(false), timeout);
        }}
      />

      <div className={'ray-grid__cell--span-full'}>
        <div className={styles.mediumTitle}>
          <h3>
            <FormattedMessage
              id="tabs.personal.form.title"
              defaultMessage="Network credentials"
            />
          </h3>
        </div>

        <CopyableInput
          ref={usernameRef}
          id="username"
          text={credentials.username}
          placeholder={intl.formatMessage({
            id: 'tabs.personal.form.fields.username',
            defaultMessage: 'Username',
          })}
          isReadonly={true}
          labelText={
            <FormattedMessage
              id="tabs.personal.form.fields.username"
              defaultMessage="Username"
            />
          }
          onAction={resetFormState}
        />

        <CopyableInput
          ref={passwordRef}
          id="password"
          text={credentials.password}
          placeholder={intl.formatMessage({
            id: 'tabs.personal.form.fields.password',
            defaultMessage: 'Password',
          })}
          isReadonly={true}
          labelText={
            <FormattedMessage
              id="tabs.personal.form.fields.password"
              defaultMessage="Password"
            />
          }
          onAction={resetFormState}
        />

        <Block>
          {!isRecentlyReseted ? (
            <button
              className={classNames(
                'ray-button ray-button--primary',
                styles.resetButton,
              )}
              onClick={() => {
                resetPassword();
                resetFormState();
              }}
              disabled={isResetting}
            >
              {isResetting ? (
                <Loader type="Oval" color="#FFF" height={13} width={13} />
              ) : (
                intl.formatMessage({
                  id: 'tabs.personal.form.actions.reset',
                  defaultMessage: 'Reset credentials',
                })
              )}
            </button>
          ) : (
            <></>
          )}

          <Link
            title={
              <FormattedMessage
                id="tabs.personal.reportForm.link"
                defaultMessage="Offensive password?"
              />
            }
            action={() => {
              if (reportModalRef.current) {
                reportModalRef.current.show();
              }
            }}
          />
        </Block>

        <Block>
          {isRecentlyReseted ? (
            <div className={styles.notification}>
              <FormattedMessage
                id="tabs.personal.form.messages.afterReset"
                values={{
                  network: (
                    <strong className={styles.deviceName}>
                      {credentials.network_name}
                    </strong>
                  ),
                }}
                defaultMessage="Please allow one minute before connecting to the ''{network}'' network in order for your new credentials to be applied across all systems."
              />
            </div>
          ) : (
            <></>
          )}
        </Block>

        <Block>
          {isReportSent ? (
            <div
              className={classNames(
                styles.successfulNotification,
                styles.notification,
              )}
            >
              <FormattedMessage
                id="tabs.personal.reportForm.messages.afterReport"
                defaultMessage="Thank you for helping us become better! Please click on the 'Reset credentials' button above in order to change your password."
              />
            </div>
          ) : (
            <></>
          )}
        </Block>

        <InstructionsAccordion
          username={credentials.username}
          password={credentials.password}
          network_name={credentials.network_name}
        />
      </div>
    </div>
  );
};
