const appUrls: { [key: string]: string } = {
  development:
    'http://localhost:8080/api/v1/credentials/report-about-offensive-password',
  staging:
    'https://wpa-enterprise-enrollment.phoenix.dev.wwrk.co/api/v1/credentials/report-about-offensive-password',
  production:
    'https://wpa-enterprise-enrollment.hydra.prod.wwrk.co/api/v1/slack/report-about-offensive-password',
  // 'https://wpa-enterprise-enrollment.hydra.prod.wwrk.co/api/v1/credentials/report-about-offensive-password', // not yet in prod
};

export const slackService = (appEnv: string, token: string) => ({
  reportAboutOffensivePassword: async (reason: string): Promise<void> => {
    const resp = await fetch(appUrls[appEnv], {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        Reason: reason,
      }),
    });

    if (resp.status !== 204) {
      throw Error('invalid status');
    }
  },
});
