export interface MemberLocation {
  uuid: string;
  full_name: string;
}

export type ErrorResponse = {
  code: number;
  status: string;
  message?: string;
};

const appUrls: { [key: string]: string } = {
  development: 'http://localhost:8080/api/v1/locations',
  staging:
    'https://wpa-enterprise-enrollment.phoenix.dev.wwrk.co/api/v1/locations',
  production:
    'https://wpa-enterprise-enrollment.hydra.prod.wwrk.co/api/v1/locations',
};

export const getLocationsClient = (appEnv: string) => ({
  getLocations: async (
    token: string,
  ): Promise<MemberLocation[] | ErrorResponse> => {
    const response = await fetch(appUrls[appEnv], {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.json();
  },

  getLocationByUUID: async (
    token: string,
    uuid: string,
    locale: string,
  ): Promise<MemberLocation | ErrorResponse> => {
    const response = await fetch(`${appUrls[appEnv]}/${uuid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'User-Locale': locale,
      },
    });

    return response.json();
  },
});
