export interface WeSecureWifiCredentialsInterface {
  username: string;
  password: string;
  network_name: string;
  device_network_name: string;
  shared_key: string;
  wifi_corp_devices: boolean;
}

export type ErrorResponse = {
  code: number;
  status: string;
  message?: string;
};

const appUrls: { [key: string]: string } = {
  development: 'http://localhost:8080/api/v1/credentials',
  staging:
    'https://wpa-enterprise-enrollment.phoenix.dev.wwrk.co/api/v1/credentials',
  production:
    'https://wpa-enterprise-enrollment.hydra.prod.wwrk.co/api/v1/credentials',
};

export const getWifiClient = (appEnv: string) => ({
  getWifiCredentials: async (
    token: string,
  ): Promise<WeSecureWifiCredentialsInterface | ErrorResponse> => {
    const response = await fetch(appUrls[appEnv], {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.json();
  },

  resetWifiCredentials: async (
    token: string,
  ): Promise<WeSecureWifiCredentialsInterface | ErrorResponse> => {
    const response = await fetch(`${appUrls[appEnv]}?reset=true`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.json();
  },
});
