import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../WifiConnect.module.scss';

type ModalProps = {
  title: JSX.Element;
  okText?: JSX.Element;
  isSelfClosable?: boolean;
  onOK: () => void;
  onCancel?: () => void;
  children?: React.ReactNode;
};

export interface ModalInterface {
  show: () => void;
  hide: () => void;
  toggleOK: () => void;
  toggleCancel: () => void;
  enableOK: () => void;
  disableOK: () => void;
  enableCancel: () => void;
  disableCancel: () => void;
}

export const Modal = forwardRef<ModalInterface, ModalProps>(
  ({ title, okText, isSelfClosable, onOK, onCancel, children }, ref) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isOkDisabled, setIsOkDisabled] = useState<boolean>();
    const [isCancelDisabled, setIsCancelDisabled] = useState<boolean>();

    useImperativeHandle(ref, () => ({
      show() {
        document.body.style.overflow = 'hidden';
        setIsVisible(true);
      },
      hide() {
        setIsVisible(false);
        document.body.style.overflow = 'initial';
      },
      toggleOK() {
        setIsOkDisabled(isOkDisabled ? undefined : true);
      },
      toggleCancel() {
        setIsCancelDisabled(isCancelDisabled ? undefined : true);
      },
      enableOK() {
        setIsOkDisabled(undefined);
      },
      disableOK() {
        setIsOkDisabled(true);
      },
      enableCancel() {
        setIsCancelDisabled(undefined);
      },
      disableCancel() {
        setIsCancelDisabled(true);
      },
    }));

    const ok = () => {
      onOK();
      if (isSelfClosable === undefined || isSelfClosable === true) {
        setIsVisible(false);
        document.body.style.overflow = 'initial';
      }
    };

    const cancel = () => {
      setIsVisible(false);
      document.body.style.overflow = 'initial';
      if (onCancel) {
        onCancel();
      }
    };

    return (
      <div style={{ display: isVisible ? 'block' : 'none' }}>
        <div className={styles.modal}>
          <div className={styles.modalTitle}>{title}</div>

          {children}

          <div className={styles.modalFooter}>
            <button
              className={classNames(
                'ray-button ray-button--primary',
                styles.button,
              )}
              onClick={ok}
              disabled={isOkDisabled}
            >
              {okText ? (
                okText
              ) : (
                <FormattedMessage id="modal.actions.ok" defaultMessage="OK" />
              )}
            </button>

            <button
              className={classNames(
                'ray-button ray-button--secondary',
                styles.button,
              )}
              onClick={cancel}
              disabled={isCancelDisabled}
            >
              <FormattedMessage
                id="modal.actions.cancel"
                defaultMessage="Cancel"
              />
            </button>
          </div>
        </div>
        <div
          className={styles.modalBackdrop}
          onClick={!isCancelDisabled ? cancel : () => {}}
        />
      </div>
    );
  },
);
