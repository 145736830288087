import {
  isChrome,
  isChromium,
  isEdge,
  isEdgeChromium,
  isFirefox,
  isOpera,
  isSafari,
} from 'react-device-detect';

export const isSupported = () =>
  isChrome ||
  isChromium ||
  isEdgeChromium ||
  isEdge ||
  isFirefox ||
  isOpera ||
  isSafari;
